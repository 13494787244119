import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import sortCartsAbstracts from '~/composables/api/cartConditions/sortCartsAbstracts';
import handleError from '~/composables/api/handleResponse';
import {
    fetchOrderDetermination
} from '~/composables/api/apiFactories/fetchOrderDetermination';
import type { ApiResponse } from '~/composables/types/api/apiResponse';

export async function deleteCartsAbstracts(cartUuid: string): Promise<CartMappedItem[] | ApiResponse> {
    try {
        const { fetchCcCartApi } = fetchOrderDetermination();

        await fetchCcCartApi(`/carts/${cartUuid}`, {
            method: 'DELETE'
        });
        const response: any = await fetchCcCartApi('/carts');

        return sortCartsAbstracts(response);
    } catch (error: any) {
        return handleError(error);
    }
}
