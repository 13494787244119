import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import handleError from '~/composables/api/handleResponse';
import sortCartsAbstracts from '~/composables/api/cartConditions/sortCartsAbstracts';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getCartsAbstracts(): Promise<ApiResponse | CartMappedItem[]> {
    try {
        const { fetchCcCartApi } = fetchOrderDetermination();

        const response: CartMappedItem[] = await fetchCcCartApi(
            '/carts'
        );

        return sortCartsAbstracts(response);
    } catch (error: any) {
        return handleError(error);
    }
}
