import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';

export default function sortCartsAbstracts(response: CartMappedItem[]) {
    const data: CartMappedItem[] = response.map((x: any) => ({
        companyBusinessUnitName: null,
        createdByName: x.createdBy,
        id: x.id,
        name: x.name,
        sumEk: x.basePriceSum ?? 0,
        sumUpe: x.listPriceSum ?? 0,
        tempCreatedAt: x.createdAt,
        tempUpdatedAt: x.modifiedAt,
        updatedByName: x.modifiedBy,
        quantitySum: x.quantitySum,
        state: x.state,
        userFlowCartState: x.userFlowCartState,
        netEk: x.finalPriceSum,
        deletable: x.deletable,
        cancelable: x.cancelable,
    }));

    data.sort((cart1:CartMappedItem, cart2:CartMappedItem) => {
        const date1 = new Date(cart1.tempUpdatedAt);
        const date2 = new Date(cart2.tempUpdatedAt);

        return date2.getTime() - date1.getTime();
    });

    return data;
}
