import getCartsAbstracts from '~/composables/api/cartConditions/getCartsAbstracts';
import { deleteCartsAbstracts } from '~/composables/api/cartConditions/deleteCartsAbstracts';
import { createCartsAbstracts } from '~/composables/api/cartConditions/createCartsAbstracts';
import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import updateCartsStatus from '~/composables/api/cartConditions/updateCartsStatus';
import moment from 'moment';

export default function useCartsAbstracts() {
    const MINIMUM_NAME_LENGTH = 2;
    const MAXIMUM_NAME_LENGTH = 40;
    const CARTNAME_TESTING_PREFIX = '#TEST ';

    const { $t, $can, $hasRole } = useNuxtApp();
    const toasts = useToasts();
    const { getUserData, userData } = useUser();
    const carts: Ref<CartMappedItem[]> = ref([]);
    const isLoading = ref(false);

    const errorMessage = ref({
        CREATE: '',
        LOAD: '',
        DELETE: '',
        UPDATE: '',
    }) as any;

    const resetErrorMessages = () => {
        errorMessage.value = {
            CREATE: '',
            LOAD: '',
            DELETE: '',
            UPDATE: '',
        };
    };

    const setErrorMessage = (errorStatus: string, type: string) => {
        const cartCreateSuffix = errorStatus === '600' ? '_cart_create' : '';

        errorMessage.value[type] = `Cart.error_${errorStatus}${cartCreateSuffix}`;
        isLoading.value = false;
    };

    const generateCartName = async() => {
        await getUserData();

        let cartName = `VO-WK ${userData.value.id} ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
        if ($can(PermissionTypes.TRAINING)) {
            cartName = `${CARTNAME_TESTING_PREFIX} ${cartName}`;
        }

        return cartName;
    };

    const loadCartsAbstract = async() => {
        if ($hasRole(RoleTypes.SUPPLIER)) {
            return;
        }

        resetErrorMessages();
        isLoading.value = true;

        let newCartResponse = await getCartsAbstracts();

        await getUserData();
        if (!Array.isArray(newCartResponse)) {
            if (newCartResponse.status) {
                setErrorMessage(newCartResponse.status.toString(), 'LOAD');
            }

            return;
        }

        isLoading.value = false;
        let updatedCarts = newCartResponse;

        if (!updatedCarts.some((cart: CartMappedItem) => (cart.state !== 'CLOSED'))) {
            newCartResponse = await createCartsAbstracts(await generateCartName());
            if (Array.isArray(newCartResponse)) {
                updatedCarts = newCartResponse;
                toasts.add({
                    type: 'success',
                    headline: $t('Cart.created_headline'),
                    text: $t('Cart.created_message'),
                });
            }
        }

        carts.value = updatedCarts;
    };

    const createCartAbstract = async(cartName: string) => {
        resetErrorMessages();
        isLoading.value = true;

        if ($can(PermissionTypes.TRAINING)) {
            cartName = CARTNAME_TESTING_PREFIX + cartName;
        }

        const newCartResponse = await createCartsAbstracts(cartName);

        if (!Array.isArray(newCartResponse)) {
            if (newCartResponse.status) {
                setErrorMessage(newCartResponse.status.toString(), 'CREATE');
            }

            return;
        }

        isLoading.value = false;
        toasts.add({
            type: 'success',
            headline: $t('Cart.created_headline'),
            text: $t('Cart.created_message'),
        });

        carts.value = newCartResponse;
    };

    const deleteCartAbstract = async(cartUuid: string) => {
        resetErrorMessages();
        isLoading.value = true;
        const newCartResponse = await deleteCartsAbstracts(cartUuid);

        await getUserData();

        if (!Array.isArray(newCartResponse)) {
            if (newCartResponse.status) {
                setErrorMessage(newCartResponse.status.toString(), 'DELETE');
            }

            return;
        }

        isLoading.value = false;
        toasts.add({
            type: 'success',
            headline: $t('Cart.deleted_headline'),
            text: $t('Cart.deleted_message'),
        });

        carts.value = newCartResponse;
    };

    const changeCartStatusAbstract = async(cartId: string, status: CartMappedItem['userFlowCartState']) => {
        resetErrorMessages();
        isLoading.value = true;

        const updateCartResponse = await updateCartsStatus(cartId, status);

        if (typeof updateCartResponse !== 'boolean') {
            if (updateCartResponse.status) {
                setErrorMessage(updateCartResponse.status.toString(), 'UPDATE');
            }

            return;
        }

        await loadCartsAbstract();
        isLoading.value = false;
        toasts.add({
            type: 'success',
            headline: $t(`Cart.status_headline`),
            text: $t(`Cart.status_message`),
        });
    };

    const isValidCartName = (cartName: string) => (
        cartName.length > MINIMUM_NAME_LENGTH && cartName.length < MAXIMUM_NAME_LENGTH
    );

    return {
        createCartAbstract,
        deleteCartAbstract,
        changeCartStatusAbstract,
        loadCartsAbstract,
        isValidCartName,
        generateCartName,
        carts,
        errorMessage,
        isLoading,
    };
}
