import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import sortCartsAbstracts from '~/composables/api/cartConditions/sortCartsAbstracts';
import handleError from '~/composables/api/handleResponse';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import {
    fetchOrderDetermination
} from '~/composables/api/apiFactories/fetchOrderDetermination';

export async function createCartsAbstracts(cartName: string): Promise<CartMappedItem[] | ApiResponse> {
    try {
        const { fetchCcCartApi } = fetchOrderDetermination();

        const createCartResponse: any = await fetchCcCartApi('/carts', {
            method: 'POST',
            body: {
                name: cartName
            }
        });

        if (createCartResponse.status) {
            throw (createCartResponse);
        }

        const response: CartMappedItem[] = await fetchCcCartApi('/carts');

        return sortCartsAbstracts(response);
    } catch (error: any) {
        return handleError(error);
    }
}
