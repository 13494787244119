import handleError from '~/composables/api/handleResponse';
import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function(cartUuid: string, status: CartMappedItem['userFlowCartState']): Promise<boolean | ApiResponse> {
    const { fetchCcCartApi } = fetchOrderDetermination();

    try {
        await fetchCcCartApi(`/carts/${cartUuid}/user-flow-state`, {
            method: 'PATCH',
            body: {
                userFlowCartState: status,
            }
        });

        return true;
    } catch (error: any) {
        return handleError(error);
    }
}
